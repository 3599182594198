<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    open-delay="300"
    scrollable
    transition="dialog-top-transition"
  >
    <template #activator="{ on }">
      <v-btn color="primary" icon text v-on="on">
        <icon-edit size="1.2rem" />
      </v-btn>
    </template>

    <v-card v-if="dialog">
      <v-toolbar color="grey lighten-2" flat>
        <v-toolbar-title>{{ paymentMethod.name }}</v-toolbar-title>
      </v-toolbar>

      <v-list v-if="arCompanyPaymentMethods.length" rounded>
        <v-list-item-group v-model="selected" color="primary">
          <template v-for="(obPaymentMethod, index) in arCompanyPaymentMethods">
            <company-payment-method-item-preview
              :key="`company-payment-method-${obPaymentMethod.id}${index}`"
              :payment-method="obPaymentMethod"
            />
          </template>
        </v-list-item-group>
      </v-list>

      <v-card-text class="pt-4">
        <v-row>
          <template v-for="item in items">
            <v-col :key="`item-edit-${item}`" cols="12" lg="6">
              <template v-if="item == 'bank_name'">
                <bank-select v-model="bankId" />
              </template>

              <template v-else-if="item == 'account_serial'">
                <form-field-text
                  :value="obConfig.account_serial"
                  label="account.serial"
                  @input:debounce="setValue($event, item)"
                />
              </template>

              <template v-else-if="item == 'account_number'">
                <form-field-text
                  :value="obConfig.account_number"
                  label="account.number"
                  @input:debounce="setValue($event, item)"
                />
              </template>

              <template v-else-if="item == 'issue_at'">
                <form-field-date
                  v-model="obConfig.issue_at"
                  label="issue.date"
                />
              </template>

              <template v-else-if="item == 'due_at'">
                <form-field-date v-model="obConfig.due_at" label="due.date" />
              </template>

              <template v-else-if="item == 'account_type'">
                <bank-account-type v-model="obConfig.account_type" />
              </template>

              <template v-else-if="item == 'number'">
                <credit-card-field
                  :value="obConfig.number"
                  hide-details
                  @input:debounce="setValue($event, item)"
                />
              </template>

              <template v-else-if="item === 'account_code'">
                <account-code-select
                  :currency-id="currencyId"
                  :payment-method-code="sAccountingPaymentMethodType"
                  :value="obConfig.account_code"
                  @change="onSelectAccount($event, item)"
                />
              </template>

              <template v-else>
                <form-field-text
                  :label="item"
                  :value="$_.get(obConfig, item)"
                  @input:debounce="setValue($event, item)"
                />
              </template>
            </v-col>
          </template>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="close">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import { InvoicePaymentMethodConfig } from "../../mixins/InvoiceMixin";
import { AppModule } from "@/store/app";
import {
  Account,
  InvoiceMovementType,
  PaymentMethod,
  PaymentMethodData,
} from "@planetadeleste/vue-mc-gw";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";

import BankSelect from "@/modules/banks/components/BankSelect.vue";
import BankAccountType from "@/components/form/fields/BankAccountType.vue";
import CreditCardField from "@/components/form/fields/CreditCard.vue";
import CompanyPaymentMethodItemPreview from "@/modules/companies/components/CompanyPaymentMethodItemPreview.vue";
import {
  InvoicePaymentMethodCode,
  InvoicePaymentMethodConfigItems,
} from "@/config/invoicePaymentMethods";
import { number } from "mathjs";
import {
  assign,
  filter,
  findIndex,
  get,
  isMatch,
  isNil,
  pick,
  set,
} from "lodash";
import { EventBus } from "@/services/event-bus";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";

@Component({
  components: {
    AccountCodeSelect,
    BankSelect,
    BankAccountType,
    CreditCardField,
    CompanyPaymentMethodItemPreview,
  },
})
export default class InvoicePaymentMethodConfigForm extends Mixins(
  InvoiceMixin
) {
  @VModel({ type: Object }) obConfig!: InvoicePaymentMethodConfig;
  @Prop(Array) readonly items!: string[];
  @Prop(Object) readonly paymentMethod!: PaymentMethod;
  @Prop(Boolean) readonly open!: boolean;
  @Prop(Number) readonly currencyId!: number;

  dialog = false;
  arCompanyPaymentMethods: PaymentMethodData[] | Record<string, any>[] = [];

  get sPaymentMethodType(): InvoicePaymentMethodCode | undefined {
    return this.paymentMethod?.get("code") as
      | InvoicePaymentMethodCode
      | undefined;
  }

  get sAccountingPaymentMethodType(): InvoicePaymentMethodCode | undefined {
    const sCode = this.sPaymentMethodType;
    if (!sCode) {
      return undefined;
    }

    if (sCode !== "CHEQUE") {
      return sCode;
    }

    return this.isSales ? "CHEQUE_RECIBIDO" : "CHEQUE_EMITIDO";
  }

  get bankId() {
    if (!this.obConfig.bank_name || isNil(this.obConfig.bank_name)) {
      return null;
    }

    const sBank = this.obConfig.bank_name as any;
    const obFindData = isNaN(sBank) ? { name: sBank } : { id: number(sBank) };
    const obBank = AppModule.banks.find(obFindData);
    return obBank ? obBank.id : null;
  }

  set bankId(sValue: number | null) {
    if (!sValue) {
      return;
    }

    const obBank = AppModule.banks.find({ id: sValue });
    if (obBank) {
      set(this.obConfig, "bank_name", obBank.name);
    }
  }

  get selected() {
    if (!this.sPaymentMethodType) {
      return -1;
    }

    return findIndex(this.arCompanyPaymentMethods, (obItem) => {
      const obItemConfig = get(obItem, "pivot.config");
      const arConfigKeyList = get(
        InvoicePaymentMethodConfigItems,
        this.sPaymentMethodType as InvoicePaymentMethodCode
      );

      if (!arConfigKeyList) {
        return false;
      }

      const obCurrentConfig = pick(this.obConfig, arConfigKeyList);

      return obItemConfig ? isMatch(obItemConfig, obCurrentConfig) : false;
    });
  }

  set selected(iValue: number) {
    if (iValue === -1) {
      return;
    }

    const obSelected = this.arCompanyPaymentMethods[iValue];
    const obSelectedConfig = get(obSelected, "pivot.config", {});
    const obConfig = assign(this.obConfig, obSelectedConfig);

    this.$emit("input", obConfig);
    this.close();
  }

  get isSale() {
    const arMovementTypeList = [
      InvoiceMovementType.CODE_SALES,
      InvoiceMovementType.CODE_DEBIT_NOTE,
    ];
    return arMovementTypeList.includes(this.iMovementType);
  }

  async mounted() {
    await this.loadCompanyPaymentMethods();

    if (this.open) {
      this.dialog = true;
    }
  }

  close() {
    EventBus.emit(
      "invoice.paymentMethodConfig.dialog.closed",
      this.paymentMethod
    );
    this.dialog = false;
  }

  async loadCompanyPaymentMethods() {
    if (
      !AppModule.company ||
      (this.isSale && this.sPaymentMethodType === "CHEQUE")
    ) {
      return;
    }

    const obResponse = await AppModule.company.getPaymentMethods();
    if (obResponse) {
      this.arCompanyPaymentMethods = filter(
        obResponse.getData().data,
        (obData) => {
          return obData.id == this.paymentMethod.id;
        }
      );
    }
  }

  onSelectAccount(obModel: Account | null, sKey: string) {
    this.setValue(obModel ? obModel.code : "", sKey);
  }

  setValue(sValue: string, sKey: string) {
    this.$set(this.obConfig, sKey, sValue);
  }
}
</script>
